import React from 'react';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';

const Contact = () => {
  return (
    <section id="contact">
      <Container>
        <Title title="音楽のリクエストを送る" />
        <div className="contact-wrapper">
          <p className="contact-wrapper__text">
            利用したい音楽がここにない場合、リクエストを送ることで音楽が追加されるかもしれません。
          </p>
          <p className="contact-wrapper__text">
            ただし、あくまで余暇を利用して作成を行うため、作曲のための時間がなかなか取れない場合、お時間をいただきます。そのため、期待せずにお待ちいただけたらと思います。
          </p>
          <p className="contact-wrapper__text">
            また、難しいリクエストやイメージがつかないものについても対応できませんので、その点ご了承ください。
          </p>
          <p className="contact-wrapper__text">
            音楽のリクエストフォームにはGoogleフォームを利用しています。
          </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdrVU4BZ09byVnx9yfW_puxudIMOWIJ9cLirCApEI-i-ZCSYQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="cta-btn cta-btn--resume"
          >
            リクエストを送る
          </a>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
